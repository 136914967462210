import { Route } from 'react-router-dom';

import { AuthGuard } from 'components/AuthGuard';
import { MainLayout } from 'components/Layout';
import { DriverRoutes } from 'features/drivers/routes';
import { lazyImport } from 'utils/lazyImport';

import { AdminRouteGuard } from './AdminRouteGuard';
import { AppRouteConfig } from './appRouteConfig';

const { AddressBooks } = lazyImport(
  () => import('features/records/routes/customers/AddressBooks'),
  'AddressBooks'
);

const { OperationsRoutes } = lazyImport(() => import('features/operations'), 'OperationsRoutes');

const { AllCustomerAssets } = lazyImport(
  () => import('features/records/routes/customers/AllCustomerAssets'),
  'AllCustomerAssets'
);
const { SettingRoutes } = lazyImport(() => import('features/settings/routes'), 'SettingRoutes');
const { TankMonitorRoutes } = lazyImport(
  () => import('features/tankMonitor/routes'),
  'TankMonitorRoutes'
);
const { RecordRoutes } = lazyImport(() => import('features/records'), 'RecordRoutes');
const { PlaceholderRoutes } = lazyImport(() => import('features/placeholder'), 'PlaceholderRoutes');
const { ShiftRoutes } = lazyImport(() => import('features/routing'), 'ShiftRoutes');
const { OrdersRoutes } = lazyImport(() => import('features/orders'), 'OrdersRoutes');
const { DashboardRoutes } = lazyImport(() => import('features/dashboard.new'), 'DashboardRoutes');
const { DeliveryOrders } = lazyImport(
  () => import('features/dashboard.new/components/DeliveryOrders'),
  'DeliveryOrders'
);
const { RecurringOrderPage } = lazyImport(
  () => import('features/dashboard.new/pages/RecurringOrderPage'),
  'RecurringOrderPage'
);
const { ShiftBoardRoutes } = lazyImport(() => import('features/shifts'), 'ShiftBoardRoutes');
const { ProfileRoutes } = lazyImport(() => import('features/profile'), 'ProfileRoutes');
const { StaffRoutes } = lazyImport(() => import('features/staff'), 'StaffRoutes');

const { Graph } = lazyImport(
  () => import('features/dashboard.new/components/Graph/Graph'),
  'Graph'
);
const { PricingRoutes } = lazyImport(() => import('features/pricing'), 'PricingRoutes');
const { Dashboard } = lazyImport(() => import('javascript/containers/Dashboard'), 'Dashboard');
const { QrCodeList } = lazyImport(
  () => import('javascript/containers/dispatch/qr_codes'),
  'QrCodeList'
);
const { ModalTypeDetailPage } = lazyImport(
  () => import('javascript/containers/Shifts/components/Modal/ModalTypeDetailPage'),
  'ModalTypeDetailPage'
);
const { DispatchRoutes } = lazyImport(() => import('features/dispatch'), 'DispatchRoutes');

const { ReportRoutes } = lazyImport(() => import('features/reports/routes'), 'ReportRoutes');

export const privateRoutes = (
  <>
    <Route
      key={'detailModalFromDelivery'}
      path={`/shift-details/:id`}
      element={<ModalTypeDetailPage />}
    />
    <Route path="*" element={<AuthGuard />}>
      <Route path="*" element={<MainLayout />}>
        {(['customers', 'self_customer'] as const).map((path) => (
          <Route key={path} path={`${path}/*`} element={<RecordRoutes which={path} />} />
        ))}
        {(['route_config'] as const).map((path) => (
          <Route key={path} path={`${path}/*`} element={<OperationsRoutes which={path} />} />
        ))}
        <Route path="" element={<Dashboard />} />
        <Route path={AppRouteConfig.DRIVERS_TIME_SPENT.path} element={<DriverRoutes />} />
        <Route path="dispatch/*" element={<DispatchRoutes />} />
        <Route path="orders/*" element={<OrdersRoutes />} />
        <Route path="pricing/*" element={<PricingRoutes />} />
        <Route path="customer_assets" element={<AllCustomerAssets />} />
        <Route path="point_of_contacts" element={<AddressBooks />} />
        <Route path="shift_plans/*" element={<ShiftRoutes />} />
        <Route path="reports/*" element={<ReportRoutes />} />
        <Route
          path="settings/*"
          element={
            <AdminRouteGuard>
              <SettingRoutes />
            </AdminRouteGuard>
          }
        />
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route path="profile/*" element={<ProfileRoutes />} />
        {/* we may remove this later on start */}
        {/* TODO: Move this route to DashboardRoutes and rename DashboardRoutes component to CustomerPortalROutes÷ */}
        <Route path="customer-portal/dashboard" element={<DashboardRoutes />} />
        <Route path="customer-portal/delivery-orders" element={<DeliveryOrders />} />
        <Route path="customer-portal/recurring-orders" element={<RecurringOrderPage />} />
        <Route path="customer-portal/graph" element={<Graph />} />
        <Route path="tank_monitors/*" element={<TankMonitorRoutes />} />

        <Route path="staff/*" element={<StaffRoutes />} />

        <Route path="dispatch/qr_codes/all" element={<QrCodeList />} />
        {/* we may remove this later on end */}
        <Route path="*" element={<PlaceholderRoutes />} />
        <Route path="shift_boards/*" element={<ShiftBoardRoutes />} />
      </Route>
    </Route>
  </>
);
